import React, { useContext, useState } from "react";
import AuthContext from "./AuthProvider";
import { Alert, Button, Container, Form } from "react-bootstrap";


const Login = () => {
    const { loginUser } = useContext(AuthContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [alert, setAlert] = useState(null);

    const handleUsernameChange = (e) => {
        e.target.isInvalid = false
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        loginUser(e, setAlert); // Pass setAlert to loginUser function
    };

    return (
        <div className="auth-inner">
            <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <Form.Group>
                        <Form.Control
                            type="text"
                            name="username"
                            placeholder="Enter username"
                            onChange={handleUsernameChange}
                            isInvalid={alert?.username}
                        />
                        <Form.Control.Feedback type="invalid">{alert?.username}</Form.Control.Feedback>
                    </Form.Group>
                </div>
                <div className="mb-3">
                    <Form.Control
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        onChange={handlePasswordChange}
                        isInvalid={alert?.password}
                    />
                    <Form.Control.Feedback type="invalid">{alert?.password}</Form.Control.Feedback>
                </div>
                <Button type="submit">Submit</Button>
            </Form>
            {alert && (
                <Alert variant={alert.variant} onClose={() => setAlert(null)} dismissible>
                    {alert?.detail}
                </Alert>
            )}
        </div>
    )
}

export default Login;