import React, { useState, useEffect, useContext } from "react"
import axiosInstance from "./axiosInstance";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "./AuthProvider";

const RaceList = () => {
    let { user } = useContext(AuthContext)
    const [races, setRaces] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const [newRace, setNewRace] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const [buttonValue, setButtonValue] = useState("Create");

    const openModal = () => { setShowModal(true); setNewRace(null) };
    const closeModal = () => setShowModal(false);

    useEffect(() => {
        axiosInstance.get('/races/')
            .then(response => {
                setRaces(response.data);
            })
            .catch(error => {
                console.log(error?.response);
            })
    }, []);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (newRace?.id) {
            axiosInstance.patch(`/races/${newRace.id}/`, newRace)
                .then(response => {
                    const updatedRaces = races.map(race => {
                        if (race.id === response.data.id) {
                            return response.data;
                        }
                        return race;
                    })
                    setNewRace(response.data);
                    setRaces(updatedRaces);
                    setFormErrors(null);
                    setButtonValue("Saved");
                    setTimeout(() => {
                        console.log("revert");
                        setButtonValue('Update');
                    }, 2000);
                })
                .catch(error => {
                    setFormErrors(error.response.data);
                })
        } else {
            axiosInstance.post('/races/', newRace)
                .then(response => {
                    setRaces([...races, response.data]);
                    setNewRace(response.data);
                    setFormErrors(null);
                    setButtonValue("Saved");
                    setTimeout(() => {
                        console.log("revert");
                        setButtonValue('Update');
                    }, 2000);
                })
                .catch(error => {
                    console.log(error.response.data);
                    setFormErrors(error.response.data);
                })
        }
    }

    const handleDelete = (e, index) => {
        e.preventDefault();
        const raceId = races[index].id;
        axiosInstance.delete(`/races/${raceId}/`)
            .then(response => {
                const updatedRaces = [...races];
                updatedRaces.splice(index, 1);
                setRaces(updatedRaces);
            })
    }

    return (
        <>
            {user?.is_super && (<Button onClick={openModal}>Add</Button>)}
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Lieu</th>
                        <th>Distance</th>
                        <th>Participants</th>
                    </tr>
                </thead>
                <tbody>
                    {races.map((item, index) => (
                        <tr key={item.id}>
                            <td><Link to={`/races/${encodeURIComponent(item.id)}`}>{item.name}</Link></td>
                            <td>{item.date}</td>
                            <td>{item.lieu}</td>
                            <td>{item.distance}</td>
                            <td>{item.runner_count}</td>
                            {user?.is_super &&
                                <td><Button onClick={(e) => handleDelete(e, index)}>Delete</Button></td>
                            }
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={closeModal}>
                <span>{newRace?.id}</span>
                <Form onSubmit={handleFormSubmit}>
                    <Modal.Header closeButton>
                        <Form.Group>
                            <Form.Control
                                name="name"
                                placeholder="Name of the race"
                                onChange={(e) => setNewRace({ ...newRace, name: e.target.value })}
                                isInvalid={formErrors?.name}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.name}</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Header>
                    <Modal.Body>


                        <Form.Group>
                            <Form.Control
                                name="date"
                                type="date"
                                placeholder="Date"
                                onChange={(e) => setNewRace({ ...newRace, date: e.target.value })}
                                isInvalid={formErrors?.date}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.date}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                name="lieu"
                                placeholder="Lieu"
                                onChange={(e) => setNewRace({ ...newRace, lieu: e.target.value })}
                                isInvalid={formErrors?.lieu}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.lieu}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Control
                                name="distance"
                                type="number"
                                placeholder="Distance"
                                step="0.001"
                                onChange={(e) => setNewRace({ ...newRace, distance: e.target.value })}
                                isInvalid={formErrors?.distance}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.distance}</Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" variant="primary" >{buttonValue}</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )

}

export default RaceList;