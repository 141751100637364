import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'
import { AuthLayout } from './components/AuthProvider';
import PrivateRoute from './components/PrivateRoute';
import RaceList from './components/RaceList';
import ReaderList from './components/ReaderList';
import RunnerList from './components/RunnerList';
import Login from './components/Login';
import Logout from './components/Logout';
import Race from './components/Race';
import RaceScan from './components/RaceScan';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter(createRoutesFromElements(
  <Route element={<AuthLayout />}>
    <Route path="/" element={<App />}>
      <Route index element={<RaceList />} />
      <Route path='/races/:id' element={<Race />} />
      <Route path='/races/:id/scan' element={<RaceScan />} />
      <Route path='/readers' element={<PrivateRoute><ReaderList /></PrivateRoute>} />
      <Route path='/runners' element={<PrivateRoute><RunnerList /></PrivateRoute>} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
    </Route>
  </Route>
));

root.render(
  <RouterProvider router={router} />
)

// root.render(
//   <React.StrictMode>
//     <Route path="/" element={<App />}>
//     </Route>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
