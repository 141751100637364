import React, { useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import Papa from "papaparse";
import moment from "moment";
import axiosInstance from "./axiosInstance"

const RunnerCSV = () => {
    const [showModal, setShowModal] = useState(false);
    const [file, setFile] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const handleFileChange = (e) => {
        console.log("change")
        if (e.target.files) {
            Papa.parse(e.target.files[0], {
                header: true,
                skipEmptyLines: true,
                dynamicTyping: false,
                encoding: "UTF-8",
                transform: function (value, column) {
                    if (column === 'birth') {
                        value = moment(value, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('YYYY-MM-DD');
                    }
                    return value;
                },
                complete: function (results) {
                    setFile(results.data)
                },
                error: function (error) {
                    console.log('error');
                },

            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosInstance.post('/runners/', file)
            .then(response => {

            })
            .catch(error => {
                setFormErrors(error.response.data)
            })
    }

    return (<>
        <Button onClick={openModal}>Upload</Button>
        <Modal show={showModal} onHide={closeModal} size="xl">
            <Modal.Header>

            </Modal.Header>
            <Modal.Body>
                <Form.Control type="file"
                    onChange={handleFileChange}
                    accept=".csv,.xlsx,.xls" />

                <Table>
                    <thead>
                        <tr>
                            <th>Firstname</th>
                        </tr>
                    </thead>
                    <tbody>
                        {file && file.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Form.Control
                                        defaultValue={item.firstname}
                                        name="firstname"
                                        isInvalid={formErrors && formErrors[index]?.firstname}
                                    // onChange={(e) => handleFileChangeInput(e, index)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors && formErrors[index]?.firstname}</Form.Control.Feedback>
                                </td>
                                <td>
                                    <Form.Control
                                        defaultValue={item.name}
                                        name="name"
                                        isInvalid={formErrors && formErrors[index]?.lastName}
                                    // onChange={(e) => handleFileChangeInput(e, index)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors && formErrors[index]?.lastName}</Form.Control.Feedback>
                                </td>
                                <td>
                                    <Form.Control
                                        defaultValue={item.birth}
                                        type="date"
                                        name="birth"
                                        isInvalid={formErrors && formErrors[index]?.birthDate}
                                    // onChange={(e) => handleFileChangeInput(e, index)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors && formErrors[index]?.birthDate}</Form.Control.Feedback>
                                </td>
                                <td>
                                    <Form.Control
                                        defaultValue={item.gender}
                                        name="gender"
                                        isInvalid={formErrors && formErrors[index]?.gender}
                                    // onChange={(e) => handleFileChangeInput(e, index)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors && formErrors[index]?.gender}</Form.Control.Feedback>
                                </td>
                                <td>{item.ageCategory}</td>
                                <td>
                                    <Form.Control
                                        defaultValue={item.nationality}
                                        name="nationality"
                                        isInvalid={formErrors && formErrors[index]?.nationality}
                                    // onChange={(e) => handleFileChangeInput(e, index)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors && formErrors[index]?.nationality}</Form.Control.Feedback>
                                </td>
                                <td>
                                    <Form.Control
                                        defaultValue={item.club}
                                        name="clubOrAssociation"
                                        isInvalid={formErrors && formErrors[index]?.clubOrAssociation}
                                    // onChange={(e) => handleFileChangeInput(e, index)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors && formErrors[index]?.clubOrAssociation}</Form.Control.Feedback>
                                </td>
                                <td>
                                    <Form.Control
                                        defaultValue={item.dossard}
                                        type="number"
                                        name="Dossard"
                                        isInvalid={formErrors && formErrors[index]?.Dossard}
                                    // onChange={(e) => handleFileChangeInput(e, index)}
                                    />
                                    <Form.Control.Feedback type="invalid">{formErrors && formErrors[index]?.Dossard}</Form.Control.Feedback>
                                </td>
                            </tr>
                        ))}
                        <Button onClick={handleSubmit}>Upload</Button>
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    </>
    )
}

export default RunnerCSV;