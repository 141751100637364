import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL + "/api",
});

axiosInstance.interceptors.request.use(
    async config => {
        const token = JSON.parse(localStorage.getItem("token"));
        if (token) {
            config.headers = {
                'Authorization': `Bearer ${token.access}`,
                'Accept': 'application/json',
                'Content-Type': 'Application/json'
            }
        }
        return config;
    }, error => {
        return Promise.reject(error)
    })

axiosInstance.interceptors.response.use((response) => {
    return response;

}, async (error) => {
    const originalRequest = error?.config;
    if (error.response.status === 401 && !originalRequest._retry && (originalRequest.url !== "/api/token/" || originalRequest.url !== "/api/token/refresh/")) {
        originalRequest._retry = true;
        const authTokens = JSON.parse(localStorage.getItem("token"));
        if (!authTokens) {
            return Promise.reject(error);
        }
        try {
            const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/token/refresh/", { refresh: authTokens?.refresh })
            const data = response.data;
            authTokens.access = data.access;
            localStorage.setItem('token', JSON.stringify(authTokens));
            return axiosInstance(originalRequest);
        } catch (refreshError) {
            // Gestion d'erreur pour la requête de rafraîchissement ici
            console.error("Erreur lors de la tentative de rafraîchissement du jeton :", refreshError);
            localStorage.removeItem('token');
            // return redirect('/login');
            return Promise.reject(refreshError);
        }
    }
    return Promise.reject(error);
});


export default axiosInstance;