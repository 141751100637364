import { createContext, useState } from "react";
import { useOutlet, useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import axiosInstance from "./axiosInstance.js";

const AuthContext = createContext()

export default AuthContext;

export const AuthLayout = () => {
    const outlet = useOutlet()

    return (
        <AuthProvider>{outlet}</AuthProvider>
    )
}

export const AuthProvider = ({ children }) => {
    let [user, setUser] = useState(localStorage.getItem("token") ? jwtDecode(localStorage.getItem("token")) : null);
    let [authTokens, setAuthTokens] = useState(localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null);

    const navigate = useNavigate();

    let loginUser = async (e, setAlert) => {
        axiosInstance
            .post("/token/", {
                username: e.target.username.value,
                password: e.target.password.value
            })
            .then((response) => {
                localStorage.setItem('token', JSON.stringify(response.data));
                setAuthTokens(response.data);
                setUser(jwtDecode(response.data.access));
                navigate('/')
            })
            .catch(error => {
                setAlert(error?.response?.data);
            })
    }

    let logoutUser = () => {
        // e.preventDefault()
        localStorage.removeItem('token')
        setAuthTokens(null)
        setUser(null)
    }

    let contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
    }

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}