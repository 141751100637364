import React, { useContext } from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import AuthContext from "./AuthProvider";

export default function Header() {
    let { user } = useContext(AuthContext);
    // let { user } = null;

    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href={`${process.env.REACT_APP_SUBDIRECTORY}/`}>Running</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                        {user?.is_super ? (<Nav.Link href={`${process.env.REACT_APP_SUBDIRECTORY}/readers`}>Readers</Nav.Link>) : null}
                        {user?.is_super ? (<Nav.Link href={`${process.env.REACT_APP_SUBDIRECTORY}/runners`}>Runners</Nav.Link>) : null}
                    </Nav>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className="px-2 text-capitalize">{user ? user.username : ""}</Navbar.Text>
                        {user ? (
                            <Nav.Link href={`${process.env.REACT_APP_SUBDIRECTORY}/logout`}>Logout</Nav.Link>
                        ) : (
                            <Nav.Link href={`${process.env.REACT_APP_SUBDIRECTORY}/login`}>Login</Nav.Link>
                        )}
                    </Navbar.Collapse>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}