import React, { useEffect, useState, useContext } from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContext from "./AuthProvider";
import axiosInstance from "./axiosInstance";
import ReaderModal from "./ReaderConfigModal";

// TO DO ADD A READER

const ReaderList = () => {
    const [readers, setReaders] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedReader, setSelectedReader] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const [buttonValue, setButtonValue] = useState("Update");

    const openModal = (id) => {
        console.log(id);
        if (id) {
            axiosInstance.get(`/readers/${id}/`)
                .then(response => {
                    setSelectedReader(response.data)
                })
        }
        setShowModal(true)
    }
    const closeModal = () => { setShowModal(false); setSelectedReader({}); };

    const handleChangeReader = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue = type === 'checkbox' ? checked : value;
        if (name === "config_report_every_n_tags") {
            if (newValue === "")
                newValue = null;
        }
        setSelectedReader(prevSelected => ({ ...prevSelected, [name]: newValue }));
        setFormErrors(prevFormErrors => ({ ...prevFormErrors, [name]: null }));
    }

    const handleSubmitReader = (e) => {
        e.preventDefault();
        if (selectedReader?.id) {
            axiosInstance.patch(`/readers/${selectedReader.id}/`, selectedReader)
                .then(response => {
                    const updatedReaders = readers.map(reader => {
                        if (reader.id === response.data.id) {
                            return response.data;
                        }
                        return reader;
                    });
                    setReaders(updatedReaders);
                    setFormErrors(null);
                    setButtonValue("Saved");
                    setTimeout(() => {
                        console.log("revert");
                        setButtonValue('Update');
                    }, 2000);
                })
                .catch(error => {
                    setFormErrors(error.response.data);
                })
        }
    }

    const handleDelete = (e, index) => {
        e.preventDefault();
        const readerId = readers[index].id;
        axiosInstance.delete(`/readers/${readerId}/`)
            .then(response => {
                const updatedReaders = [...readers];
                updatedReaders.splice(index, 1);
                setReaders(updatedReaders)
            })
    }

    useEffect(() => {
        axiosInstance.get('/readers/')
            .then(response => {
                setReaders(response.data);
                console.log(response.data);
            })
            .catch(error => {
                // console.log(error);
            })
    }, []);
    return (
        <>
            {/* <Link onClick={openModal}>Add</Link> */}
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Online</th>
                        <th>Active</th>
                    </tr>
                </thead>
                <tbody>
                    {readers.map((item, index) => (
                        <tr key={index}>
                            <td><Link onClick={(e) => openModal(item.id)}>{item.name}</Link></td>
                            <td>{item.online ? 'Yes' : 'No'}</td>
                            <td>{item.running ? 'Yes' : 'No'}</td>
                            <td><Button onClick={(e) => handleDelete(e, index)}>Delete</Button></td>
                        </tr>

                    ))}
                </tbody>
            </Table>
            <ReaderModal showModal={showModal}
                closeModal={closeModal}
                selectedReader={selectedReader}
                formErrors={formErrors}
                handleChangeReader={handleChangeReader}
                handleSubmitReader={handleSubmitReader}
                buttonValue={buttonValue}
            />
        </>
    )
}

export default ReaderList;