import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import AuthContext from "./AuthProvider";
import axiosInstance from "./axiosInstance";
import { Accordion, Button, Table } from "react-bootstrap";
import CheckpointInline from "./CheckpointInline";
import { Pencil } from 'react-bootstrap-icons';

import useWebSocket from "react-use-websocket";
import moment from 'moment'
import 'moment-duration-format';

import RaceEditModal from "./RaceEditModal";
import RunnerCSVRace from "./RunnerCSVRace";


const Race = () => {
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => { setShowModal(false) }
    const openModal = () => { setShowModal(true) }

    const [race, setRace] = useState({});
    const { id } = useParams();
    const { user } = useContext(AuthContext);

    const { readyState, sendMessage, sendJsonMessage, lastMessage, lastJsonMessage } = useWebSocket(`${process.env.REACT_APP_WS_URL}/api/races/${id}/ws/`, {
        onOpen: () => {
            console.log('WebSocket opened');
        },
        onClose: () => {
            console.log('WebSocket closed');
        },

        shouldReconnect: () => true,
    });

    function sortRacePersonTagSet(race) {
        if (!race || !race.racepersontag_set) {
            return [];
        }

        const sortedRacePersonTagSet = race.racepersontag_set.sort((a, b) => {
            const lastCheckpointA = a.record_set[a.record_set.length - 1];
            const lastCheckpointB = b.record_set[b.record_set.length - 1];

            if (!lastCheckpointA && !lastCheckpointB) return 0;
            if (!lastCheckpointA) return 1;
            if (!lastCheckpointB) return -1;
            if (lastCheckpointA.checkpoint !== lastCheckpointB.checkpoint) {
                return lastCheckpointB.checkpoint - lastCheckpointA.checkpoint;
            }
            const timeA = new Date(lastCheckpointA.time);
            const timeB = new Date(lastCheckpointB.time);
            return timeA - timeB;
        });
        return sortedRacePersonTagSet;
    }

    useEffect(() => {
        axiosInstance.get(`/races/${id}/`)
            .then(response => {
                // sortRacePersonTagSet(response.data);
                console.log(response.data);
                setRace(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, [id]);

    useEffect(() => {
        if (lastJsonMessage) {
            if (lastJsonMessage.type === "reader.message") {
                const updatedRace = {
                    ...race,
                    checkpoint_set: race.checkpoint_set.map(checkpoint => {
                        if (checkpoint.reader && checkpoint.reader.id === lastJsonMessage.message.id) {
                            return {
                                ...checkpoint,
                                reader: lastJsonMessage.message
                            };
                        }
                        return checkpoint;
                    })
                };
                setRace(updatedRace);
            }
            else if (lastJsonMessage.type === "passing.message") {
                console.log(lastJsonMessage);
                const updatedRace = {
                    ...race,
                    racepersontag_set: race.racepersontag_set.map(racePersonTag => {
                        if (racePersonTag.id === lastJsonMessage.message.race_person_tag) {
                            // Mettez à jour le temps de passage dans la coursePersonTag correspondante
                            return {
                                ...racePersonTag,
                                record_set: [
                                    ...racePersonTag.record_set,
                                    lastJsonMessage.message
                                ]
                            };
                        }
                        return racePersonTag;
                    })
                };
                // sortRacePersonTagSet(updatedRace);
                setRace(updatedRace);
            }
            else {
                console.log(lastJsonMessage);
            }
        }
    }, [lastJsonMessage]);

    const setCheckpoints = (updatedCheckpoints) => {
        setRace({ ...race, checkpoint_set: updatedCheckpoints });
    };

    const handleStartRace = (e) => {
        axiosInstance.patch(`/races/${id}/`, { 'start': new Date() })
            .then(response => {
                setRace(response.data);
            })

    }

    const handleScanningToggle = (checkpointId, currentScanningState) => {
        const updatedRace = { ...race };
        const updatedCheckpoint = updatedRace.checkpoint_set.find(
            (checkpoint) => checkpoint.id === checkpointId
        );

        axiosInstance.patch(`/readers/${updatedCheckpoint.reader.id}/start/`, { start_inventory: !currentScanningState, mode: "running" })
            .then((response) => {
                updatedCheckpoint.reader = response.data;
                setRace(updatedRace);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const addPassage = (e, checkpointId, runnerId) => {
        e.preventDefault();
        axiosInstance.post('/records/', {
            checkpoint: checkpointId,
            race_person_tag: runnerId,
            time: moment().toISOString(),
            source: "manual"
        })
            .catch(error => {
                console.log(error);
            })
    }

    const deleteRecord = (e, id) => {
        e.preventDefault();
        axiosInstance.delete(`/records/${id}/`)
            .then(response => {
                setRace((prevRace) => {
                    const updatedRacePersonTagSet = prevRace.racepersontag_set.map((tag) => {
                        const updatedRecordSet = tag.record_set.filter(record => record.id !== id);
                        return { ...tag, record_set: updatedRecordSet };
                    });
                    return { ...prevRace, racepersontag_set: updatedRacePersonTagSet };
                });
            })
    }

    const renderAdmin = () => {
        if (user && user.is_super) {
            return (
                <>
                    <Button onClick={handleStartRace}>Start</Button>
                    <Button onClick={handleExport}>Export</Button>
                    <Link to="scan"><Button>Scan</Button></Link>
                    <CheckpointInline
                        checkpoints={race.checkpoint_set}
                        setCheckpoints={setCheckpoints}
                        handleScanningToggle={handleScanningToggle}
                    />
                    <RunnerCSVRace raceId={race.id} />
                </>
            )
        }
    }

    const diffTime = (a, b) => {
        const timeA = moment(a)
        const timeB = moment(b)
        const duration = moment.duration(timeA.diff(timeB))
        return duration.format("hh:mm:ss", { trim: false })
    }

    const handleExport = () => {
        const filtered_json = {
            "name": race.name,
            'start': race.start,
            "result": race.racepersontag_set.map(item => ({
                "tag": item.tag.epc,
                "name": item.runner ? item.runner.name : "",
                "firstname": item.runner ? item.runner.firstname : "",
                "birth": item.runner ? item.runner.birth : "",
                "time": item.record_set.map(record => ({
                    "timestamp": record.time,
                    "time": diffTime(moment(record.time), moment(race.start))
                }))
            }

            ))
        }


        const jsonString = JSON.stringify(filtered_json, null, 2); // null et 2 pour une indentation de 2 espaces
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${race.name}.json`);
        document.body.appendChild(link);
        link.click();

        // Nettoyer
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    return (
        <>
            <h1>{race.name}<Link onClick={openModal}><Pencil fontSize={20} /></Link></h1>
            <h5>{race.date}, {race.lieu}</h5>
            <h6>{race.start && moment(race.start).format("DD/MM/YY à HH:mm:ss")}</h6>
            {renderAdmin()}
            <Table>
                <thead>
                    <tr>
                        {
                            user?.is_super ? <th>EPC</th> : ""
                        }
                        {/* <th>Place</th> */}
                        <th>Nom</th>
                        <th>Dossard</th>
                        <th>Genre</th>
                        {/* <th>Categorie</th> */}
                        {race.checkpoint_set && race.checkpoint_set.map((checkpoint) => (
                            <th key={checkpoint.id}>{checkpoint.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {race.racepersontag_set && race.racepersontag_set.map((item, index) => {
                        const passingTimes = {};
                        item.record_set.forEach((passing) => {
                            if (!passingTimes[passing.checkpoint]) {
                                passingTimes[passing.checkpoint] = [];
                            }
                            passingTimes[passing.checkpoint].push({ 'time': passing.time, 'id': passing.id });
                        });
                        return (((user?.is_super) || item.runner?.name) && (<tr key={index}>
                            {user ? <td>{item.tag?.epc}</td> : ""}
                            {/* <td>{index + 1}</td> */}
                            <td>{item.runner ? item.runner.firstname : ""} {item.runner ? item.runner.name : ""}</td>
                            <td>{item.bib}</td>
                            <td>{item?.runner?.gender}</td>
                            {/* <td>{item.category}</td> */}
                            {race.checkpoint_set && race.checkpoint_set.map((checkpoint) => (
                                <td key={checkpoint.id}>
                                    <Accordion flush>
                                        <Accordion.Item eventKey={checkpoint.id}>
                                            {user?.is_super && (<Button onClick={(e) => addPassage(e, checkpoint.id, item.id)}>Passage</Button>)}
                                            <Accordion.Header>
                                                {passingTimes[checkpoint.id] && (
                                                    race.start ? `${diffTime(moment(passingTimes[checkpoint.id][passingTimes[checkpoint.id].length - 1].time), race.start)} (${moment(passingTimes[checkpoint.id][passingTimes[checkpoint.id].length - 1].time).format('HH:mm:ss')})` : moment(passingTimes[checkpoint.id][passingTimes[checkpoint.id].length - 1].time).format('DD/MM/YYYY HH:mm:ss')
                                                )}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {
                                                    passingTimes[checkpoint.id] && passingTimes[checkpoint.id].map((time, timeIndex) => (
                                                        <div key={timeIndex}>
                                                            {race.start ? `${diffTime(time.time, race.start)} (${moment(time.time).format("HH:mm:ss")})` : moment(time.time).format("d/m/YY HH:mm:ss")}
                                                            {user?.is_super ? (<Button onClick={e => deleteRecord(e, time.id)}>Delete</Button>) : ""}
                                                        </div>

                                                    ))
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </td>
                            )
                            )}
                        </tr>))
                    }
                    )}
                </tbody>
            </Table>

            <RaceEditModal
                showModal={showModal}
                closeModal={closeModal}
                race={race}
                setRace={setRace}
            />
        </>
    )
}

export default Race;