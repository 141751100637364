import { Button, Form, FormControl, FormGroup, Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap"
import axiosInstance from "./axiosInstance";
import { useState } from "react";

const RaceEditModal = ({ showModal, closeModal, race, setRace }) => {
    const [formErrors, setFormsErrors] = useState();
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name);
        setRace(prevRace => ({ ...prevRace, [name]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosInstance.patch(`/races/${race.id}/`,
            {
                name: race.name,
                date: race.date,
                lieu: race.lieu,
                start: race.start,
                distance: race.distance,
            })
            .then(response => {
                setRace(response.data);
            })
            .catch(error => {
                setFormsErrors(error.response.data);
            }
            )
    }

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    <ModalTitle>
                        <FormGroup>
                            <FormControl
                                type="text"
                                name="name"
                                placeholder="Name"
                                onChange={handleChange}
                                defaultValue={race?.name}
                            />
                        </FormGroup>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <FormControl
                            type="date"
                            name="date"
                            placeholder="Date"
                            onChange={handleChange}
                            defaultValue={race?.date}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControl
                            type="text"
                            name="lieu"
                            placeholder="Lieu"
                            onChange={handleChange}
                            defaultValue={race?.lieu}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControl
                            type="number"
                            name="distance"
                            placeholder="Distance"
                            onChange={handleChange}
                            defaultValue={race?.distance}
                        />
                    </FormGroup>
                    <FormGroup>
                        <FormControl
                            type="datetime"
                            name="start"
                            placeholder="Start time"
                            onChange={handleChange}
                            defaultValue={race?.start}
                        />
                    </FormGroup>
                    <Button type="submit">Update</Button>
                </ModalBody>

            </Form>
        </Modal>
    )
}

export default RaceEditModal;