import { useContext, useEffect } from "react"
import AuthContext from "./AuthProvider"

const Logout = () => {
    const { logoutUser } = useContext(AuthContext)

    useEffect(() => {
        logoutUser();
    }, [])

    return (<></>)
}

export default Logout;