import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import ReaderModal from "./ReaderModal";
import { BorderBottom } from "react-bootstrap-icons";

const CheckpointInline = (props) => {
    const [selectedReader, setSelectedReader] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formErrors, setFormErrors] = useState({})

    const openModal = (index) => {
        setSelectedReader(props.checkpoints[index].reader);
        setShowModal(true)
    };
    const closeModal = () => { setShowModal(false); setSelectedReader(null); };

    const handleChangeReader = (e) => {
        const { name, value, type, checked } = e.target;
        let newValue = type === 'checkbox' ? checked : value;
        if (name === "config_report_every_n_tags") {
            if (newValue === "")
                newValue = null;
        }
        setSelectedReader(prevSelected => ({ ...prevSelected, [name]: newValue }));
        setFormErrors(prevFormErrors => ({ ...prevFormErrors, [name]: null }));
    }

    const handleSubmitReader = (e) => {
        e.preventDefault();
        axiosInstance.patch(`/readers/${selectedReader.id}/`, selectedReader)
            .then(response => {
                const updatedCheckpointSet = [...props.checkpoints];
                const checkpoint = updatedCheckpointSet.findIndex(check => check.reader.id === response.data.id);
                updatedCheckpointSet[checkpoint].reader = response.data;
                props.setCheckpoints(updatedCheckpointSet);
                setFormErrors(null);
            })
            .catch(error => {
                setFormErrors(error?.response?.data);
            })
    }

    return (
        <div>
            <Row className="border-bottom">
                <Col sm={2}>Checkpoint</Col>
                <Col sm={2}>Lecteur</Col>
                <Col sm={1}>En ligne</Col>
                <Col sm={1}>Scanning</Col>
                <Col sm={1}>Mode</Col>
                <Col sm={1}>Power</Col>
                <Col sm={2}>Antennas</Col>
                <Col sm={1}></Col>
            </Row>
            {props.checkpoints && props.checkpoints.map((checkpoint, index) => (
                <Row key={index} className="border-bottom">
                    <Col sm={2} className="align-self-center">{checkpoint.name}</Col>
                    {checkpoint.reader ? (
                        <>
                            <Col sm={2} className="align-self-center"><Link onClick={() => openModal(index)}>{checkpoint.reader?.name}</Link></Col>
                            <Col sm={1} className="align-self-center">{checkpoint.reader.online ? "🟢" : "🔴"}</Col>
                            <Col sm={1} className="align-self-center">{checkpoint.reader.active ? "🟢" : "🔴"}</Col>
                            <Col sm={1} className="align-self-center">{checkpoint.reader.mode}</Col>
                            <Col sm={1} className="align-self-center">{checkpoint.reader.config_txpower}</Col>
                            <Col sm={2} className="align-self-center">{checkpoint.reader.config_antenna4 ? "🟢" : "🔴"} {checkpoint.reader.config_antenna3 ? "🟢" : "🔴"} {checkpoint.reader.config_antenna2 ? "🟢" : "🔴"} {checkpoint.reader.config_antenna1 ? "🟢" : "🔴"}</Col>
                            <Col sm={1} className="align-self-center">
                                {<Button
                                    onClick={() => props.handleScanningToggle(checkpoint.id, checkpoint.reader.start_inventory)}
                                    disabled={!checkpoint.reader.online}
                                >
                                    {checkpoint.reader.start_inventory ? "Stop" : "Start"}

                                </Button>
                                }</Col>
                        </>) : (<Col sm={10}>Lecteur non défini</Col>)}
                </Row>
            ))
            }
            <ReaderModal
                showModal={showModal}
                closeModal={closeModal}
                selectedReader={selectedReader}
                formErrors={formErrors}
                handleChangeReader={handleChangeReader}
                handleSubmitReader={handleSubmitReader}
            />
        </div>

    )
}

export default CheckpointInline;