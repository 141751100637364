import { Modal, ModalBody, ModalHeader, Form, FloatingLabel, Button } from "react-bootstrap";

const ReaderModal = ({ showModal, closeModal, selectedReader, formErrors, handleChangeReader, handleSubmitReader }) => {

    return (
        <Modal show={showModal} onHide={closeModal}>
            <Form onSubmit={handleSubmitReader}>
                <ModalHeader>
                    <Modal.Title>
                        <Form.Group>
                            <Form.Control
                                type="text"
                                name="name"
                                onChange={handleChangeReader}
                                defaultValue={selectedReader?.name}
                                isInvalid={formErrors?.name}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.name}</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <Form.Group>
                        <Form.Check
                            type="switch"
                            name="config_antenna1"
                            label="Antenna 1"
                            inline
                            checked={selectedReader?.config_antenna1}
                            onChange={handleChangeReader}
                        />
                        <Form.Check
                            type="switch"
                            name="config_antenna2"
                            label="Antenna 2"
                            inline
                            checked={selectedReader?.config_antenna2}
                            onChange={handleChangeReader}
                        />
                        <Form.Check
                            type="switch"
                            name="config_antenna3"
                            label="Antenna 3"
                            inline
                            checked={selectedReader?.config_antenna3}
                            onChange={handleChangeReader}
                        />
                        <Form.Check
                            type="switch"
                            name="config_antenna4"
                            label="Antenna 4"
                            inline
                            checked={selectedReader?.config_antenna4}
                            onChange={handleChangeReader}
                        />
                    </Form.Group>
                    <Form.Group>
                        <FloatingLabel controlId="config_txpower" label="TX Power">
                            <Form.Control
                                type="number"
                                name="config_txpower"
                                onChange={handleChangeReader}
                                defaultValue={selectedReader?.config_txpower}
                                isInvalid={formErrors?.config_txpower}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.config_txpower}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                        <FloatingLabel controlId="api_key" label="API key">
                            <Form.Control
                                type="text"
                                name="api_key"
                                onChange={handleChangeReader}
                                defaultValue={selectedReader?.api_key}
                                isInvalid={formErrors?.api_key}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.api_key}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>

                    <Form.Group>
                        <FloatingLabel controlId="population" label="Population">
                            <Form.Control
                                type="number"
                                name="config_population"
                                onChange={handleChangeReader}
                                defaultValue={selectedReader?.config_population}
                                isInvalid={formErrors?.config_population}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.config_population}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group>
                        <FloatingLabel controlId="config_duration" label="Duration report">
                            <Form.Control
                                defaultValue={selectedReader?.config_duration}
                                type="number"
                                name="config_duration"
                                onChange={handleChangeReader}
                                isInvalid={formErrors?.config_duration}
                                disabled={selectedReader?.config_report_every_n_tags}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.config_duration}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <Form.Group>
                        <FloatingLabel controlId="config_report_every_n_tags" label="Report every n tags">
                            <Form.Control
                                type="text"
                                name="config_report_every_n_tags"
                                onChange={handleChangeReader}
                                defaultValue={selectedReader?.config_report_every_n_tags}
                                isInvalid={formErrors?.config_report_every_n_tags}
                            />
                            <Form.Control.Feedback type="invalid">{formErrors?.config_report_every_n_tags}</Form.Control.Feedback>
                        </FloatingLabel>
                    </Form.Group>
                    <FloatingLabel controlId="mode" label="Mode">
                        <Form.Select
                            value={selectedReader?.mode}
                            onChange={handleChangeReader}
                            name="mode"
                        >
                            <option value=""></option>
                            <option value="running">Running</option>
                            <option value="registering">Registering</option>
                            <option value="test">Test</option>
                        </Form.Select>
                    </FloatingLabel>
                    <Button type="submit">Update</Button>
                </Modal.Body>
            </Form>
        </Modal>
    )
}

export default ReaderModal;